window.tabsInit = function (widgetID) {

    var $tabsBodyWrapper = $("#content-" + widgetID);

    var tabBodies = $tabsBodyWrapper.children(".tab-pane");

    var $tabsNavUl = $("#tabs-" + widgetID);

    tabBodies.each(function (index, tab) {
        var $tab = $(tab);
        var newTabLi = $('<li/>', { "class": 'nav-item' });
        var newTabA = $('<a/>', {
            "class": 'nav-link',
            "href": "#tabs-widget-" + $tab.data("tab-id") + "-pane",
            "data-toggle": 'tab',
            "role": 'tab'
        }).text($tab.data("title"));

        newTabLi.append(newTabA);
        $tabsNavUl.append(newTabLi);

        var $collapse = $tab.find(".collapse").first();
        var contentID = "#content-" + widgetID;
        $collapse.attr("data-parent", contentID);
    });

    var tabsNavAnchors = $tabsNavUl.find('a');

    var $firstNavAnchor = tabsNavAnchors.first();
    $firstNavAnchor.addClass("active");
    $firstNavAnchor.attr("aria-selected", "true");  

    var $firstTab = tabBodies.first();

    $firstTab.addClass("show active");
    $firstTab.find("a").first().attr("aria-expanded", "true");

    var $firstTabCardCollapse = $firstTab.find(".collapse").first();    
    $firstTabCardCollapse.addClass("show");
    
}