(function (init) {
    // The global jQuery object is passed as a parameter
    init(window.jQuery, window, document);

}(function ($, window, document) {

    jQuery("main img:not(.img-fluid, .img-responsive, .img-cover)").addClass("img-fluid");

    // The rest of the code goes here!    


    var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    /* Events Carousel */
    var allCarouselInstances = [];
    var allFlickityCarousel = $(".widget-upcoming-events .events-carousel");
    if (allFlickityCarousel && allFlickityCarousel.length > 0) {
        for (let i = 0; i < allFlickityCarousel.length; i++) {
            var $eventsCarousel = allFlickityCarousel[i];

            var flktyInstance = new Flickity($eventsCarousel, {
                cellAlign: windowWidth < 768 ? 'left' : 'center',
                pageDots: false,
                prevNextButtons: false,
                freeScroll: true
            });

            allCarouselInstances.push(flktyInstance);

            // events carousel custom prev button 
            $($eventsCarousel).parent().find('.button--prev').on('click', function () {
                flktyInstance.previous();
            });

            // events carousel custom next button 
            $($eventsCarousel).parent().find('.button--next').on('click', function () {
                flktyInstance.next();
            });

            flktyInstance.on('change', function () {
                if (flktyInstance.selectedIndex - 1 < 0) {
                    $($eventsCarousel).parent().find('.button--prev').addClass("disabled");
                } else {
                    $($eventsCarousel).parent().find('.button--prev').removeClass("disabled");
                }

                if (flktyInstance.selectedIndex + 1 >= flktyInstance.cells.length) {
                    $($eventsCarousel).parent().find('.button--next').addClass("disabled");
                } else {
                    $($eventsCarousel).parent().find('.button--next').removeClass("disabled");
                }
            });
        }
    }

    window.onresize = function () {
        for (let i = 0; i < allCarouselInstances.length; i++) {
            allCarouselInstances[i].destroy();
        }

        allCarouselInstances = [];

        var allFlickityCarousel = $(".widget-upcoming-events .events-carousel");
        if (allFlickityCarousel && allFlickityCarousel.length > 0) {
            for (let i = 0; i < allFlickityCarousel.length; i++) {
                var $eventsCarousel = allFlickityCarousel[i];

                var flktyInstance = new Flickity($eventsCarousel, {
                    cellAlign: windowWidth < 768 ? 'left' : 'center',
                    pageDots: false,
                    prevNextButtons: false,
                    freeScroll: true
                });

                allCarouselInstances.push(flktyInstance);

                // events carousel custom prev button 
                $($eventsCarousel).parent().find('.button--prev').on('click', function () {
                    flktyInstance.previous();
                });

                // events carousel custom next button 
                $($eventsCarousel).parent().find('.button--next').on('click', function () {
                    flktyInstance.next();
                });

                flktyInstance.on('change', function () {
                    if (flktyInstance.selectedIndex - 1 < 0) {
                        $($eventsCarousel).parent().find('.button--prev').addClass("disabled");
                    } else {
                        $($eventsCarousel).parent().find('.button--prev').removeClass("disabled");
                    }

                    if (flktyInstance.selectedIndex + 1 >= flktyInstance.cells.length) {
                        $($eventsCarousel).parent().find('.button--next').addClass("disabled");
                    } else {
                        $($eventsCarousel).parent().find('.button--next').removeClass("disabled");
                    }
                });

                $($eventsCarousel).parent().find('.button--prev').addClass("disabled");
                $($eventsCarousel).parent().find('.button--next').removeClass("disabled");
            }
        }
    };

    /* timeline */

    $('.timeline .collapse').on('hide.bs.collapse', function () {
        $(this).parent('.card').removeClass("active");
        if (!$(this).parents('.timeline').find('.card.active').length) {
            $(this).parent('.card').siblings('.card').removeClass('before-active');
        }
    });

    $('.timeline .collapse').on('show.bs.collapse', function () {
        var $card = $(this).parent('.card');
        $card.addClass("active");
        $card.prevAll('.card').addClass("before-active");
        $card.nextAll('.card').removeClass("before-active");
        $card.removeClass('before-active');
    });

    $('.timeline .card').on('click', function () {
        $(this).find('.collapse').collapse('toggle');
    });


    //video tabs (carousel)

    var $videoTabsCarousel = $('.section-video-tabs .carousel');
    var $videoTabsNavCarousel = $videoTabsCarousel.next('.carousel-nav');
    var videoTabNavs = $videoTabsCarousel.find('.video-tab-nav');
    $videoTabsNavCarousel.append(videoTabNavs);


    /* Hero Carousel */
    var $heroCarousel = $('.section-hero-carousel .carousel');

    $heroCarousel.on('ready.flickity', function () {

        var dotTitles = [];
        $(this).find('.hero-carousel-item').each(function () {
            dotTitles.push($(this).data("dotTitle"));
        });

        $(this).find('.flickity-page-dots .dot').each(function () {

            var $dotSVG = $(document.createElementNS("http://www.w3.org/2000/svg", "svg"))
                .addClass("progress-ring")
                .attr({
                    width: 36,
                    height: 36
                });

            var $dotCircle = $(document.createElementNS("http://www.w3.org/2000/svg", "circle"))
                .addClass("progress-ring__circle")
                .attr({
                    stroke: "#df613b",
                    "stroke-width": 3,
                    fill: "transparent",
                    r: 17, //radius = (width / 2) - (strokeWidth * 2)
                    cx: 18,
                    cy: 18
                });

            $dotSVG.append($dotCircle);

            $(this).append($dotSVG);
            $(this).append("<div class='dot-title'>" + dotTitles.shift() + "</div>");
        });
    }).on('select.flickity', function (event, index) {

        var flkty = Flickity.data(".section-hero-carousel .carousel");
        //console.log("hero carousel autoplay state: " + flkty.player.state);

        if (flkty.player.state !== "playing") {
            $(this).find(".flickity-page-dots").addClass("autoplay-stopped");
        }

    });

    /*header search bar*/

    var searchInput = document.getElementById("searchInput");
    var searchButton = document.getElementById("searchButton");

    var gotoSearchPage = function (url, keywords) {
        if (!(keywords || "").length) return;

        var url = url + "#q=" + encodeURIComponent(keywords);
        window.location = url;
    };

    var inputSearchKeyUpEvent = function (event) {
        // Number 13 is the "Enter" key on the keyboard
        if (event.keyCode === 13) {
            // Cancel the default action, if needed
            event.preventDefault();
            var keywords = jQuery(this).val();

            var url = jQuery(this).data("url");
            gotoSearchPage(url, keywords);
        }
    };

    var searchButtonClickEvent = function (event) {

        // Cancel the default action, if needed
        event.preventDefault();

        var keywords = $("#searchInput").val();
        var url = $("#searchInput").data("url");
        gotoSearchPage(url, keywords);

    };

    searchInput.addEventListener("keyup", inputSearchKeyUpEvent);
    searchButton.addEventListener("click", searchButtonClickEvent);

    /*share button*/

    $(".btn-share").click(function () {
        var shareTitle = $(this).data("title");
        var shareUrl = $(this).data("url");

        if (navigator.share) {
            navigator.share({
                title: shareTitle,
                url: shareUrl
            }).then(() => {
                console.log('Thanks for sharing!');
            }).catch(console.error);
        } else {
            var shareModal = $("#shareModal");

            //shareModal.find(".btn-facebook").attr("href", "https://www.facebook.com/sharer.php?u=" + encodeURIComponent(shareUrl));
            //shareModal.find(".btn-twitter").attr("href", "https://twitter.com/intent/tweet?url=" + encodeURIComponent(shareUrl) + "&text=" + encodeURIComponent(shareTitle));
            //shareModal.find(".btn-linkedIn").attr("href", "https://www.linkedin.com/shareArticle?mini=true&url=" + encodeURIComponent(shareUrl) + "&text=" + encodeURIComponent(shareTitle));
            //shareModal.find(".btn-email").attr("href", "mailto:%20?body=" + encodeURIComponent(shareUrl));

            //shareModal.find(".addthis_inline_share_toolbox").data({url: shareUrl, title: shareTitle});
            shareModal.modal('show');
        }
    });

    $("a[href^=mailto]").click(function (e) {
        e.preventDefault();
        var mailto = $(this).attr("href");
        var email = mailto.replace("mailto:", "").replace(" at ", "@").replace(" dot ", ".");
        window.open('mailto:' + email);
    });

    $("a[target=_blank]").attr("rel", "noopener noreferrer");

    $(".side-nav-title").attr("aria-expanded", (windowWidth >= 992).toString());

    var checkboxes = $('.rss-checkboxes-wrapper input[type=checkbox]');
    if (checkboxes && checkboxes.length > 0) {
        checkboxes.each(function () {
            $(this).on("click", function () {
                var values = [];
                checkboxes.each(function (index, item) {
                    if ($(this).is(":checked")) {
                        values.push($(this).val());
                    }
                });

                let url = $("#rssLinkHidden").val();
                if (!url) {
                    url = ""
                }

                if (values.length > 0) {
                    url = url + `?filter=${encodeURIComponent(values.join('|'))}`;
                }

                $("#rssLink").val(url);
                $("#rssAnchor").text(url);
                $("#rssAnchor").attr("href", url);
            });
        });
    }

    var copyRssLink = $("#copy-rss-link");
    if (copyRssLink && copyRssLink.length > 0) {
        copyRssLink.on("click", function () {
            // Get the text field
            var copyText = document.getElementById("rssLink");

            if (copyText) {
                // Select the text field
                copyText.select();
                copyText.setSelectionRange(0, 99999); // For mobile devices

                // Copy the text inside the text field
                navigator.clipboard.writeText(copyText.value);

                var rssAnchor = $("#rssAnchor").popover({ placement: 'top', content: 'Copied!' });
                rssAnchor.popover("show");

                setTimeout(function () {
                    rssAnchor.popover("hide");
                    rssAnchor.popover("dispose");
                }, 500)
            }
        });
    }
}));