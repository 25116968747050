const $ = require("jquery");
window.jQuery = $;
window.$ = $;

const Flickity = require('flickity');

require('flickity-imagesloaded');
require('flickity-as-nav-for');
require('flickity-fade');
require('flickity-bg-lazyload');

window.Flickity = Flickity;

//require('./flickity.pkgd.min.js');
window.lity = require("./lity.min.js");
require("./popper.min.js");
require("./bootstrap.min.js");
require("./Custom/accordionSection.js");
require("./Custom/tabsSection.js");
require("./site.js");